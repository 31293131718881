import { useModal } from "~/components/modal";
import { HowToUseSteps } from "~/server/types/brand";
import { HowToUseContent } from "../brand_l2/how_to_use_content";
import { Tncs } from "../brand_l2/tncs";

type SecondaryCtasProps = {
  brandId?: string;
  howToUseSteps?: HowToUseSteps[];
  tncResId: string;
  buttonClass?: string;
};

export function SecondaryCtas(props: SecondaryCtasProps) {
  const { setIsModalOn, updateModalContentGetter } = useModal()!;

  return (
    <div class="inline-flex h-fit w-full items-start justify-start gap-4">
      <div
        class="flex h-10 shrink grow basis-0 cursor-pointer items-center justify-center rounded-[30px] border-[1px] border-solid border-baseTertiaryDark bg-baseTertiaryLight px-2.5 py-1.5 hover:border-basePrimaryDark darkTheme:border-[#313538] darkTheme:bg-baseTertiaryDark darkTheme:hover:border-[#505050]"
        style={{
          "box-shadow": "0px 1px 2px 0px rgba(0, 0, 0, 0.04);",
        }}
        classList={{
          [props.buttonClass!]: !!props.buttonClass,
        }}
        onClick={(event) => {
          event.stopPropagation();
          updateModalContentGetter(() => {
            return (
              <HowToUseContent
                brandId={props.brandId}
                howToUseStepsList={props.howToUseSteps!}
              />
            );
          });

          setIsModalOn(true);
        }}
      >
        <div class="text-mediumBold text-textDark">How to use / redeem</div>
      </div>
      <div
        class="flex h-10 shrink grow basis-0 cursor-pointer items-center justify-center rounded-[30px] border-[1px] border-solid border-baseTertiaryDark bg-baseTertiaryLight px-2.5 py-1.5 hover:border-basePrimaryDark darkTheme:border-[#313538] darkTheme:bg-baseTertiaryDark darkTheme:hover:border-[#505050]"
        style={{
          "box-shadow": "0px 1px 2px 0px rgba(0, 0, 0, 0.04);",
        }}
        classList={{
          [props.buttonClass!]: !!props.buttonClass,
        }}
        onClick={() => {
          updateModalContentGetter(() => {
            return <Tncs tncResId={props.tncResId} />;
          });

          setIsModalOn(true);
        }}
      >
        <div class="text-mediumBold text-textDark">Things to note</div>
      </div>
    </div>
  );
}
