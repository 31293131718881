import { hubbleAssuredBigger, hubbleHeaderBgLines } from "~/assets/assets";
import { Spacer } from "~/widgets/spacer";
import { Divider } from "~/widgets/divider";
import { PhosphorIcon } from "~/widgets/icons";
import { For } from "solid-js";
import { CloseModalButton } from "../modal";

type BulletDotProps = {
  fillColor?: string;
};

function BulletDot(props: BulletDotProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
    >
      <circle cx="18" cy="9" r="2" fill={props.fillColor ?? "#DDDDDD"} />
    </svg>
  );
}

function AssuredModalHeader() {
  return (
    <div class="flex h-fit w-full flex-col items-center gap-3">
      <img
        class="h-[100px] w-[100px]"
        src={hubbleAssuredBigger}
        alt="Hubble assured"
      />
      <div class="flex flex-col items-center gap-2">
        <span class="text-h2 text-textDark">Hubble assured</span>
        <span class="text-normal text-textNormal">
          100% money back guarantee
        </span>
      </div>
    </div>
  );
}

function AssuredPromise() {
  return (
    <div
      class="flex w-full flex-col gap-4 rounded-2xl p-5 text-baseTertiaryLight"
      style="background: linear-gradient(156deg, #12394F 0.11%, #034038 98.71%), linear-gradient(180deg, #12394F 0%, #142827 100%), #FFF; box-shadow: 0px 4px 4px 0px rgba(33, 36, 39, 0.02);"
    >
      <div class="text-bold text-baseTertiaryLight">Our promise</div>
      <Divider bgColor="#204C63" />
      <div class="flex flex-col gap-5">
        <div class="flex flex-col gap-2">
          <div class="flex flex-row items-baseline gap-2">
            <span>
              <PhosphorIcon name="lightning" fontSize={19} />
            </span>
            <span class="text-h5">Fast resolution</span>
          </div>
          <div class="inline-flex flex-row gap-2">
            <span>
              <BulletDot />
            </span>
            <span class="text-medium text-baseTertiaryDark">
              If you face any technical issue while redeeming the card, get a
              resolution within 7 business days
            </span>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <div class="flex flex-row items-baseline gap-2">
            <span>
              <PhosphorIcon name="arrows-counter-clockwise" fontSize={19} />
            </span>
            <span class="text-h5">Easy refunds</span>
          </div>
          <div class="inline-flex flex-row gap-2">
            <span>
              <BulletDot />
            </span>
            <span class="text-medium text-baseTertiaryDark">
              Full refund guaranteed if we are not able to resolve the issue
            </span>
          </div>
          <div class="inline-flex flex-row gap-2">
            <span>
              <BulletDot />
            </span>
            <span class="text-medium text-baseTertiaryDark">
              Gift cards should not be used in any transaction nor added to
              brand wallet
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

const howItWorksSteps = [
  "Contact us via chat support or email us at support@hubble.com within 3 days of purchasing your brand card",
  "Share your issue (error screenshot will be needed in case of technical issue)",
  "Provide details of brand, voucher amount and details (number & pin)",
  "Allow 7 days for us to check and process your refund",
];

function AssuredHowItWorks() {
  return (
    <div class="shadow-sm flex flex-col gap-4 rounded-2xl border border-neutral-200 p-5">
      <div class="text-bold text-textDark">How it works</div>
      <Divider bgColor="#D9D9D9" />
      <div class="flex flex-col gap-[14px]">
        <For each={howItWorksSteps}>
          {(step, index) => (
            <div class="text=[#313538] flex flex-row items-start gap-2">
              <div class="inline-flex h-5 w-5 flex-col items-center justify-center gap-2.5 rounded-[32px] bg-neutral-800 p-2.5">
                <div class="text-[12px] font-bold leading-none text-baseTertiaryLight">
                  {index() + 1}
                </div>
              </div>
              <div class="text-medium text-textDark">{step}</div>
            </div>
          )}
        </For>
      </div>
    </div>
  );
}

export function HubbleAssuredDetails() {
  return (
    <div
      class="flex h-fit w-full rounded-t-2xl border border-neutral-200 bg-baseTertiaryLight p-3 lg:rounded-b-2xl lg:p-6"
      // style="background:linear-gradient(180deg, #DBEAEF 0%, #FFF 24.29%);"
      style={{
        background: `url('${hubbleHeaderBgLines}') no-repeat, linear-gradient(180deg, #DBEAEF 0%, #FFF 24.29%)`,
      }}
    >
      <div class="absolute right-6 top-6">
        <CloseModalButton />
      </div>

      <div class="w-full">
        <AssuredModalHeader />
        <Spacer height={24} />
        <AssuredPromise />
        <Spacer height={30} />
        <AssuredHowItWorks />
      </div>
    </div>
  );
}
